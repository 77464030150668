import type { WalletAdapter } from '.';

export const gobyAdapter = new Proxy(
  {
    on: (event: string, callback: (...args: any[]) => void) => {
      return window.chia.on(event, callback);
    },
    chainId: async () => {
      const chainId = await window.chia.request({ method: 'chainId' });
      if (chainId === 'testnet10') {
        return 'testnet11';
      }
      return chainId;
    },
  } as WalletAdapter,
  {
    get(target, key) {
      if (key in target) {
        return target[key as keyof typeof target];
      }
      return function (params: any) {
        return window.chia.request({ method: key, params });
      };
    },
  }
);

export const isGobyInstalled = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const { chia } = window;
  return Boolean(chia && chia.isGoby);
};
